import React from "react";
import { TfiEmail } from "react-icons/tfi";
import { IoLocationOutline } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import { BiLogoLinkedin } from "react-icons/bi";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.icons}>
        <div>
          <TfiEmail size={35} color="orange" />
        </div>
        <div>
          <IoLocationOutline size={35} color="orange" />
        </div>
        {/* <div>
          <IoMdCall size={35} color="orange" />
        </div> */}
        {/* <div>
          <BiLogoLinkedin size={35} color="orange" />
        </div> */}
      </div>

      <div className={classes.text}>
        <div className={classes.text}>info@shavano.biz</div>
        <div className={classes.text}>
          26 Bayshore Road , <br />
          #06-04 ,<br />
          Singapore 469972
        </div>
        {/* <div className={classes.text}>Phone: +65 9168 3590</div> */}
        {/* <div className={classes.text}>Get connected Follow us on Linkedin </div> */}
      </div>
      <div className={classes.Copyright}>
        ©{new Date().getFullYear()} All Rights Reserved Shavano Management Pte.
        Ltd
      </div>
    </div>
  );
};

export default Footer;
