import React, { useState } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import classes from "./Home.module.css";
import Card from "./Components/Cards/Card";
import RegulatoryImage from "../../Assets/Background/Regulatory_Compliance.png";
import Quote from "./Components/Testimonials/Quote";
import Logo from "../../Assets/Logo.png";
import Footer from "../../Components/Footer/Footer";

const Home = () => {
  const [values, setvalues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const onChange = (e) => {
    const temp = { ...values };
    temp[e.target.name] = e.target.value;
    setvalues(temp);
  };

  const regulatoryCompliance = [
    {
      name: "eMAS Licence Applications",
      link: "mas-licence-applications-services",
    },
    {
      name: "Compliance",
      link: "compliance-consulting-advising",
    },
    {
      name: "Risk Management",
      link: "risk-management-consultant-services",
    },
    // {
    //   name: "Anti-Money Laundering and Countering the Financing of Terrorism (AML/CFT) Due Diligence",
    //   link: "anti-money-laundering-training",
    // },
  ];

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div className={classes.maintainence}>
        Our website is currently undergoing maintenance; some services might not
        work. Please check back later to try them out.
      </div>
      <Navbar />
      <h2 className={classes.AboutHeading}>ABOUT US</h2>
      <div className={classes.AboutText}>
        At Shavano Management Pte. Ltd, we take pride in providing one-stop
        compliance solutions to our clients. Trusted by clients since 2021, we
        understand the challenges that businesses face in a fast-paced world.
        <br />
        <br />
        Our mission is to help you navigate the constantly changing regulatory
        environment which you operate in. Proactive and intelligent, we empower
        you to accelerate your business growth by keeping your business secure
        and compliant.
      </div>
      <div className={classes.Service}>
        <div className={classes.ServiceContainer}>
          <h2>Our Services</h2>
          <div className={classes.Services}>
            <Card
              Heading={"Regulatory Compliance"}
              imageSource={RegulatoryImage}
              links={regulatoryCompliance}
            />
            {/* <Card
              Heading={"Regulatory Compliance"}
              imageSource={RegulatoryImage}
              links={regulatoryCompliance}
            />
            <Card
              Heading={"Regulatory Compliance"}
              imageSource={RegulatoryImage}
              links={regulatoryCompliance}
            /> */}
          </div>
        </div>
      </div>
      {/* <div className={classes.TestimonialContainer}>
        <h1>TESTIMONIALS</h1>
        <div className={classes.Testimonials}>
          <Quote
            Text={
              "Lymon has been a trusted provider that addresses our needs from incorporation to investment operation, supporting us with a diverse set of needs to Timefolio AM. Lymon’s leadership in consultancy across multiple avenues makes them a superior partner and I look forward to the partnership growing even further."
            }
            Writer={
              "Mr. Jae Lee, CEO of Timefolio Asset Management Singapore Pte. Ltd."
            }
          />
          <Quote
            Text={
              "Lymon has been a trusted provider that addresses our needs from incorporation to investment operation, supporting us with a diverse set of needs to Timefolio AM. Lymon’s leadership in consultancy across multiple avenues makes them a superior partner and I look forward to the partnership growing even further."
            }
            Writer={
              "Mr. Jae Lee, CEO of Timefolio Asset Management Singapore Pte. Ltd."
            }
          />
          <Quote
            Text={
              "Lymon has been a trusted provider that addresses our needs from incorporation to investment operation, supporting us with a diverse set of needs to Timefolio AM. Lymon’s leadership in consultancy across multiple avenues makes them a superior partner and I look forward to the partnership growing even further."
            }
            Writer={
              "Mr. Jae Lee, CEO of Timefolio Asset Management Singapore Pte. Ltd."
            }
          />
        </div>
      </div> */}
      <div className={classes.ContactContainer}>
        <div className={classes.Heading}>Contact Us</div>
        <div className={classes.ContactForm}>
          <div className={classes.LeftContainer}>
            <img src={Logo} alt="Logo" />
          </div>
          <form className={classes.rightContainer}>
            <input
              required={true}
              placeholder="Name"
              onChange={onChange}
              className={classes.input}
              name={"name"}
              value={values.name}
            />
            <input
              required={true}
              placeholder="Email"
              onChange={onChange}
              className={classes.input}
              name={"email"}
              type="email"
              value={values.email}
            />
            <input
              required={true}
              placeholder="Phone Number"
              onChange={onChange}
              className={classes.input}
              name={"phone"}
              type="email"
              value={values.phone}
            />
            <textarea
              className={classes.message}
              required={true}
              placeholder="Add a Message"
              onChange={onChange}
              name={"message"}
              type="message"
              value={values.message}
            />
            <button className={classes.submitBtn} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
