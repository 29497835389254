import AntiMoneyLaundering from "./Pages/AntiMoneyLaundering";
import MasLicense from "./Pages/MasLicense";
import RegulatoryCompilance from "./Pages/RegulatoryCompilance";
import RiskManagement from "./Pages/RiskManagement";

export const Mapping = {
  "mas-licence-applications-services": {
    Component: <MasLicense />,
  },
  "compliance-consulting-advising": {
    Component: <RegulatoryCompilance />,
  },
  "risk-management-consultant-services": {
    Component: <RiskManagement />,
  },
  "anti-money-laundering-training": {
    Component: <AntiMoneyLaundering />,
  },
};
