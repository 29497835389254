import React from "react";
import classes from "./Card.module.css";
import { Link } from "react-router-dom";

const Card = ({ imageSource, Heading, links }) => {
  return (
    <div className={classes.CardContainer}>
      <img src={imageSource} alt="imageSource" />
      <div className={classes.Container}>
        <h3>{Heading}</h3>
        <ul>
          {links.map((element) => (
            <li>
              <Link to={`/services/${element.link}`}>{element.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Card;
