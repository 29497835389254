import React from "react";
import Img from "../../../Assets/Services/MasLicense.jpeg";
import classes from "./Common.module.css";

const RegulatoryCompilance = () => {
  var points = [
    "Tailoring and review of compliance manuals and risk framework",
    "Provision of high-quality compliance templates for clients' reference",
    "Ongoing on-site and off-site compliance support",
    "Drafting, reviewing and handling of regulatory correspondence and filings",
    "Reviewing investors files and due diligence documentation",
    "Regular compliance updates and reports, including assessment of impact",
    "Compliance calendaring",
    "Assist with MAS visits and inspections",
    "Bespoke compliance solutions according to your company's business, scale and other needs",
  ];

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <img src={Img} alt="Background" />
        <div className={classes.Heading}>
          Regulatory Compliance
          <br /> Consulting & Advisor
        </div>
      </div>
      <div className={classes.textSection}>
        <div className={classes.Content}>
          Our experienced regulatory compliance team has extensive knowledge and
          understanding of the regulatory requirements as well as best industry
          practices to guide you in your compliance and risk management
          function.
          <div className={classes.points}>
            Our services include:
            <ul>
              {points.map((element) => (
                <li>{element}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegulatoryCompilance;
