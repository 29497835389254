import React from "react";
import Img from "../../../Assets/Services/MasLicense.jpeg";
import classes from "./Common.module.css";

const MasLicense = () => {
  var points = [
    "Assess qualifications of the proposed senior management and directors",
    "Prepare the application forms and accompanying submissions​",
    "Submit the application to MAS",
    "Handle MAS requisitions and supplemental questions in the application process",
  ];

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <img src={Img} alt="Background" />
        <div className={classes.Heading}>
          Mas License
          <br /> Application
        </div>
      </div>
      <div className={classes.textSection}>
        <div className={classes.Content}>
          We have outstanding proven track record of licence applications for
          various types of financial institutions, including fund management
          firms, fintechs, financial advisers, and other financial institutions.
          <div className={classes.points}>
            We assist applicant companies to:
            <ul>
              {points.map((element) => (
                <li>{element}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasLicense;
