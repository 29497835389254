import React from "react";
import Img from "../../../Assets/Services/MasLicense.jpeg";
import classes from "./Common.module.css";

const RiskManagement = () => {
  var points = [
    "Identify and assess risks and effectiveness and efficiency of current risk   responses against the full breadth of strategic, operations, financial and   compliance risk",
    "Provision of internal audit services to help evaluate existing current   internal controls, identify internal control gaps and to test existing   controls for operating effectiveness",
    "Best practices on risk management and internal controls",
  ];

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <img src={Img} alt="Background" />
        <div className={classes.Heading}>
          Risk Management
          <br /> & Internal Audit
        </div>
      </div>
      <div className={classes.textSection}>
        <div className={classes.Content}>
          The Code of Corporate Governance expects the board to maintain a sound
          system of risk management and internal controls to safeguard
          shareholders' interest and the company's assets, and should therefore
          determine the nature and extent of the significant risks, which the
          board will be willing to take in achieving its strategic objectives.
          The board is expected to have a Risk Management and Internal Control
          Committee to oversee the adequacy and effectiveness of its risk
          management framework and internal controls over its business
          processes.
          <br />
          <br />
          Our professionals can be engaged to sit on board as independent
          directors and bring to the board their relevant expertise in their
          professional fields which is often invaluable.
          <div className={classes.points}>
            Our services include:
            <ul>
              {points.map((element) => (
                <li>{element}</li>
              ))}
            </ul>
          </div>
          <br />
          <br />
          Internal audit is an independent, objective assurance and consulting
          activity designed to add value and improve and organization's
          operations. It helps an organization accomplish its objectives in
          bringing a systematic, disciplined approach to evaluate and improve
          the effectiveness of risk management, control, and governance
          processes.
          <br />
          <br />
          We provide internal audit services for mainly financial institutions
          regulated by the Monetary Authority of Singapore or registered filing
          agents with the Accounting and Corporate Regulatory Authority of
          Singapore.
        </div>
      </div>
    </div>
  );
};

export default RiskManagement;
