import React from "react";
import Logo from "../../Assets/Logo.png";
import classes from "./Navbar.module.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.Logo}>
        <img src={Logo} alt="Logo" />
      </div>
      <div className={classes.Links}>
        <Link to="/">Home</Link>
        <Link to="/">About</Link>
        <Link to="/">Services</Link>
        <Link to="/">Events</Link>
        <Link to="/">Insights</Link>
        <Link to="/">Carrers</Link>
        <Link to="/">Contact Us</Link>
      </div>
    </div>
  );
};

export default Navbar;
