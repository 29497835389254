import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Mapping } from "./Mapping";
import classes from "./Service.module.css";

const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { slug } = useParams();

  return (
    <div className={classes.Container}>
      {Mapping[slug].Component}
      <div className={classes.ServiceFooter}>
        <div className={classes.Heading}>Our Services</div>
        <div className={classes.Points}>
          <ul>
            <li>Compliance & Regulatory</li>
            <li>Training</li>
            <li>Corportate & Business</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Service;
