import React from "react";
import Img from "../../../Assets/Services/MasLicense.jpeg";
import classes from "./Common.module.css";

const AntiMoneyLaundering = () => {
  var points = [
    "Identify and verifying financial institution's investor/customer and the relevant parties",
    "Carry out screening on companies and the relevant parties through databases on sanctions, PEPs and adverse media",
    "Provide customers due diligence reports summarizing work performed",
    "Find out more on IBF Singapore's website here.",
  ];

  var points2 = [
    "Gap analysis review of existing AML policies and procedures, and MAS regulations",
    "Carry out investor file review to ensue the completeness of   documentation and adherence to the regulatory requirements",
    "Review of remediation carried out on regulatory inspection reports",
    "Secondment of compliance personnel to assist with specific projects",
  ];

  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <img src={Img} alt="Background" />
        <div className={classes.Heading}>
          ANTI-MONEY LAUNDERING <br /> AND COUNTERING THE FINANCING OF TERRORISM
          (AML/CFT) <br /> DUE DILIGENCE Application
        </div>
      </div>
      <div className={classes.textSection}>
        <div className={classes.Content}>
          We assist with financial institutions regulated by the Monetary
          Authority of Singapore (MAS) in relation to customer due diligence,
          compliance training: Anti-Money Laundering and Countering the
          Financing of Terrorism for Capital Markets Intermediaries:
          <div className={classes.points}>
            <ul>
              {points.map((element) => (
                <li>{element}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classes.Boxes}>View Our Next Training Session</div>
        <div className={classes.Content}>
          Our training programmes are under the Financial Training Scheme (FTS)
          where IBF provides funding up to 50% (for Singapore Citizens/Singapore
          PRs aged below 40 years old), and up to 90% (for Singapore Citizens
          aged 40 years old and above). This FTS only applies to
          company-sponsored participants where the financial institution submits
          claims for the company-sponsored participants. Please refer to IBF's
          information note here for more details to qualify for IBF's funding
          scheme.
          <br />
          <br />
          We also provide the following services:
          <div className={classes.points}>
            <ul>
              {points2.map((element) => (
                <li>{element}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AntiMoneyLaundering;
