import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Service from "./Pages/Services/Service";

const Layout = (Component) => {
  return (
    <div style={{ position: "relative"}}>
      <Navbar />
      <Component />
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services/:slug" element={Layout(Service)} />
      <Route path="/about" element={Layout(About)} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default App;
